import React, { useRef, useState, useEffect } from "react";
import vdo from "../img/sample.mp4"; // Import the video from the `img` folder
import "../style/video.css"; // Import CSS for styling

const Video = () => {
  const videoRef = useRef(null); // Reference to the video element
  const [isPlaying, setIsPlaying] = useState(true); // State to track play/pause
  const [showContactButton, setShowContactButton] = useState(false);

  useEffect(() => {
    // Set a timer to show the "Contact Now" button after 30 seconds
    const timer = setTimeout(() => {
      setShowContactButton(true);
    }, 300000); // 30 seconds in milliseconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause(); // Pause the video
    } else {
      video.play(); // Play the video
    }
    setIsPlaying(!isPlaying); // Toggle the state
  };

  return (
    <div className="video-container">
      <h1>Watch This Video Till The End</h1>
      
      {/* Video Player */}
      <div className="video-wrapper">
        <video 
          ref={videoRef} 
          className="custom-video" 
          autoPlay 
         // Ensure the video is muted for autoplay to work in most browsers
          loop // Optional: loop the video if desired
        >
          <source src={vdo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Custom Controls */}
        <div className="video-controls">
          <button onClick={handlePlayPause} className="play-pause-button">
            {isPlaying ? "Pause" : "Play"}
          </button>
        </div>
        
        <p>Schedule a Free Consultation Call <br/>To Grow Your Business</p>
        <small>(Button Will be Enabled at the Middle of the Video)</small><br/>
        
        {/* "Contact Now" Button */}
        {showContactButton && (
          <a href="https://forms.fillout.com/t/oxvvTJvv8Qus" target="_blank" rel="noreferrer"><button className="contact">Book Expert Consultation Call <br/>NOW !!</button></a>
        )}
      </div>
    </div>
  );
};

export default Video;
