import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import for navigation
import PhoneInput from 'react-phone-input-2'; // Import the phone input component
import 'react-phone-input-2/lib/style.css'; // Import styles for the phone input
import "../style/PopupForm.css"; // Import a separate CSS file for the popup

const PopupForm = ({ isVisible, onClose }) => {
  const [data, setData] = useState({
    name: "",
    number: "", // This will now hold the full phone number including country code
    email: "",
    city: "",
  });
  const [isLoading, setIsLoading] = useState(false); // State for loading animation
  const [errors, setErrors] = useState({}); // State for form errors

  const navigate = useNavigate(); // Initialize the navigation hook
  
  if (!isVisible) return null;

  const { name, number, email, city } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" }); // Clear error on change
  };

  const validateForm = () => {
    let formErrors = {};
    if (!name) formErrors.name = "Name is required.";
    if (!number) {
      formErrors.number = "Mobile number is required.";
    } else if (number.length < 10) { // Adjusted validation for length check
      formErrors.number = "Mobile number must be at least 10 digits.";
    }
    if (!email) {
      formErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email format is invalid.";
    }
    if (!city) formErrors.city = "City is required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    
    if (!validateForm()) return; // Stop submission if validation fails

    setIsLoading(true); // Start the loading animation
    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/gunasekaran/google_sheets/rXFCStxyYkIckThv?tabId=Sheet1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [name, number, email, city, new Date().toLocaleString()],
          ]),
        }
      );
      await response.json();
      setData({ name: "", number: "", email: "", city: "" }); // Reset form data
      navigate("/video"); // Navigate to the Video component
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false); // Stop the loading animation after submission
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Watch Now For FREE!</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              placeholder="Enter your name"
              name="name"
              value={name}
              onChange={handleChange}
              required
            />
            {errors.name && <p className="error">{errors.name}</p>}
          </label>
          <label>
              Mobile Number:
              <PhoneInput
                // Added class for styling
                country={'in'}
                value={number}
                onChange={(value) => setData({ ...data, number: value })}
                required
              />
              {errors.number && <p className="error">{errors.number}</p>}
            </label>
                      <label>
            Email:
            <input
              type="email"
              placeholder="Enter your email"
              name="email"
              value={email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </label>
          <label>
            City:
            <input
              type="text"
              placeholder="Enter your city"
              name="city"
              value={city}
              onChange={handleChange}
              required
            />
            {errors.city && <p className="error">{errors.city}</p>}
          </label>
          <button type="submit" className="form-submit-button" disabled={isLoading}>
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm mr-1"></span>
                Submitting...
              </>
            ) : (
              "Submit"
            )}
          </button>
          <button type="button" className="form-cancel-button" onClick={onClose}>
            Close
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
