import React, { useState } from "react";
import "../style/Home.css";
import PopupForm from "./PopupForm";
import thumbnail from "../img/Thumbnail.jpg";

const Home = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="promo-container">
      {/* Heading */}
      <h1 className="promo-heading">
        For Business Owners{" "}
        <span className="highlight">Solopreneurs,</span> Freelancers,{" "}
        <span className="highlight">Self-Employed,</span> Coaches &{" "}
        <span className="highlight">Consultants Only</span>.
      </h1>
      <p className="promo-subheading">
        (Increase your Sales and Revenue 2X using Our Marketing Strategies
        within 45 Days-or You don't Pay)
      </p>

      {/* Main Content */}
      <div className="promo-content">
        <div className="promo-image-container">
          <img src={thumbnail} alt="Digital Entrepreneur" className="promo-image" onClick={togglePopup}/>
        </div>

        <div className="promo-text">
          <h2 className="promo-text-heading">
            Watch this Short and Crisp Masterclass to
          </h2>
          <ul className="promo-list">
            <li>Learn Proven, Creative Marketing Strategies</li>
            <li>Learn to Attract <br/> High-Quality Leads</li>
            <li>Learn to Convert those Leads into Life Long Customers/Clients</li>
            <li> Build your Personal Brand and Dominate your Industry</li>
          </ul>
          <h2 className="promo-text-heading">
       
          </h2>
          <button className="promo-button" onClick={togglePopup}>
            Watch Now For FREE
          </button>
        </div>
      </div>

      {/* Popup Form */}
      <PopupForm isVisible={isPopupVisible} onClose={togglePopup} />
    </div>
  );
};

export default Home;
